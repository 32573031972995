import React, { useEffect, useState } from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoIcon } from "../../InfoIcon";
import { useLiquity } from "../../../hooks/LiquityContext";
import { Badge } from "../../Badge";
import { fetchPrices } from "../context/fetchPrices";

const selector = ({
  remainingLiquidityMiningLQTYReward,
  totalStakedUniTokens
}: LiquityStoreState) => ({
  remainingLiquidityMiningLQTYReward,
  totalStakedUniTokens
});

export const Yield: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  const { remainingLiquidityMiningLQTYReward, totalStakedUniTokens } = useLiquitySelector(selector);
  const [fltyPrice, setFltyPrice] = useState<Decimal | undefined>(undefined);
  const [uniLpPrice, setUniLpPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue = remainingLiquidityMiningLQTYReward.isZero || totalStakedUniTokens.isZero;
  const fltyTokenAddress = addresses["lqtyToken"];
  const uniTokenAddress = addresses["uniToken"];

  useEffect(() => {
    (async () => {
      try {
        const { fltyPriceUSD, uniLpPriceUSD } = await fetchPrices(
          fltyTokenAddress,
          uniTokenAddress,
          true
        );
        setFltyPrice(fltyPriceUSD);
        setUniLpPrice(uniLpPriceUSD);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [fltyTokenAddress, uniTokenAddress]);

  if (hasZeroValue || fltyPrice === undefined || uniLpPrice === undefined) {
    return null;
  }

  const remainingLqtyInUSD = remainingLiquidityMiningLQTYReward.mul(fltyPrice);
  const totalStakedUniLpInUSD = totalStakedUniTokens.mul(uniLpPrice);
  const aprPercentage = remainingLqtyInUSD.div(totalStakedUniLpInUSD).mul(100);
  const apyPercentage = aprPercentage.div(100).div(365).add(1).pow(365).sub(1).mul(100);

  if (aprPercentage.isZero) {
    return null;
  }

  return (
    <Badge>
      <Text>FLTY APR {aprPercentage.toString(2)}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" sx={{ minWidth: ["auto", "400px"] }}>
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the FLTY return on staked Cake
              LP tokens.
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace", mt: 2 }}>
              ($FLTY_REWARDS / $STAKED_CAKE_LP) * 100 = <Text sx={{ fontWeight: "bold" }}> APR</Text>
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              ($
              {remainingLqtyInUSD.shorten()} / ${totalStakedUniLpInUSD.shorten()}) * 100 =
              <Text sx={{ fontWeight: "bold" }}> {aprPercentage.toString(2)}%</Text>
            </Paragraph>
            <br />
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              Daily compounded APY = (1 + APR / 365) ^ 365 - 1 = {apyPercentage.toString(2)}%
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
