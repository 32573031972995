import axios from "axios";

import { Decimal } from "@liquity/lib-base";

const Web3 = require("web3");

const abi = [
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
let web3: null | typeof Web3;

async function fetchCoingecko(tokenId: string) {
  try {
    const resp = await axios({
      method: "GET",
      url: "https://coingecko.p.rapidapi.com/simple/price",
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "coingecko.p.rapidapi.com",
        "x-rapidapi-key": "b8398f6512mshc89d315cf5b0c31p17a4bejsn378eb860e79b",
        useQueryString: true
      },
      params: {
        ids: tokenId,
        vs_currencies: "usd"
      }
    });
    return resp.data[tokenId].usd;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchPrices(uniTokenAddress: string) {
  let busdPriceUSD = await fetchCoingecko("binance-usd");
  const vaultReserveAbi = [
    {
      inputs: [],
      name: "getVaultReserve",
      outputs: [
        {
          internalType: "uint256",
          name: "baseReserve",
          type: "uint256"
        },
        {
          internalType: "uint256",
          name: "quoteReserve",
          type: "uint256"
        }
      ],
      stateMutability: "view",
      type: "function"
    }
  ];

  if (!web3) {
    web3 = new Web3(Web3.givenProvider);
  }

  const uniToken = await new web3.eth.Contract(abi.concat(vaultReserveAbi), uniTokenAddress);
  let uniTotalSupply = await uniToken.methods.totalSupply().call();
  uniTotalSupply = Decimal.from(uniTotalSupply).div(1e18);

  const {
    baseReserve: busdBalance,
    quoteReserve: flusdBalance
  } = await uniToken.methods.getVaultReserve().call();
  // Suppose price is stable
  const reservedUSD = Decimal.from(busdBalance)
    .add(Decimal.from(flusdBalance))
    .div(1e18)
    .mul(busdPriceUSD);
  const uniLpPriceUSD = reservedUSD.div(Decimal.from(uniTotalSupply));

  const fltyPriceUSD = await fetchCoingecko("fluity");
  return { fltyPriceUSD, uniLpPriceUSD };
}
