import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Box,
  Button,
  Flex,
  Heading,
  Label,
  Link,
  Select,
  Paragraph
} from "theme-ui";
import axios from "axios";
import moment from "moment";

import { Decimal } from "@liquity/lib-base";

import { SystemStats } from "../components/SystemStats";
import { InfoMessage } from "../components/InfoMessage";
import { useLiquity } from "../hooks/LiquityContext";
import { StaticRow } from "../components/Trove/Editor";
import { Icon } from "../components/Icon";
import { ActionDescription } from "../components/ActionDescription";
import { ErrorDescription } from "../components/ErrorDescription";
import { LoadingOverlay } from "../components/LoadingOverlay";
import { isClaimed, claim } from "../utils/web3api";

const startTs = 1622073600; // 5/26 17:00 PDT
// Sorted array of weeks
const weeksToExclude = [11];
const weekToRound = (week: number) => {
  let excluded = 0;
  for (let i = 0; i < weeksToExclude.length; i++) {
    if (week === weeksToExclude[i]) {
      throw new Error("invalid week!");
    }
    if (week > weeksToExclude[i]) {
      excluded += 1;
    } else {
      // Early exit if smaller
      break;
    }
  }
  return week - 1 - excluded;
};

const dateForWeek = (week: String) => {
  const ts = startTs + 604800 * (Number(week) - 1);
  return moment(ts * 1000)
    .utc()
    .format("YYYY-MM-DD");
};

export const Airdrop: React.FC = () => {
  const { account } = useLiquity();

  const currentWeek = Math.floor((Math.floor(Date.now() / 1000) - startTs) / 604800) + 1;
  const options = Array(currentWeek)
    .fill(0)
    .map((_, i) => <option key={i + 1}>{i + 1}</option>)
    .filter((_, i) => !weeksToExclude.includes(i + 1));

  const [selectedWeek, setSelectedWeek] = useState(String(currentWeek));
  const [claimData, setClaimData] = useState(undefined);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [apiState, setApiState] = useState<{ error?: string; hash?: string }>({});

  // Async initializer to detect whether current week data is available
  useEffect(() => {
    const date = dateForWeek(selectedWeek);
    axios.head(`/claims/distribution-${date}.json`).catch(() => {
      setSelectedWeek(String(Number(selectedWeek) - 1));
    });
  }, []);

  let myClaim: { amount: string; index: number; proof: Array<string> } | undefined;
  let claimable: Decimal | undefined;

  if (claimData) {
    // @ts-ignore
    myClaim = claimData.claims?.[account.toLowerCase()];
    claimable = Decimal.fromBigNumberString(myClaim?.amount || "0");
  }

  const changeWeek = (e: any) => {
    // Reset
    setSelectedWeek(e.target.value);
    setClaimData(undefined);
    setAlreadyClaimed(false);
    setApiState({});
  };

  const checkClaim = async () => {
    const date = dateForWeek(selectedWeek);
    const claimPath = `/claims/distribution-${date}.json`;
    try {
      const resp = await axios(claimPath);
      setClaimData(resp.data);
      setApiState({});
      const index = resp.data.claims?.[account.toLowerCase()]?.index;
      if (index !== undefined) {
        const round = weekToRound(parseInt(selectedWeek));
        const claimed = await isClaimed(round, index);
        setAlreadyClaimed(claimed);
      }
    } catch (e) {
      console.log(e);
      setApiState({ error: `Claim data not found.` });
    }
  };

  const doClaim = async () => {
    if (!myClaim || !claimable || claimable.isZero) {
      return;
    }
    const { index, proof } = myClaim;
    setApiState({});
    setIsWaiting(true);
    const result = await claim(
      weekToRound(parseInt(selectedWeek)),
      index,
      account,
      claimable.mul(1e18),
      proof
    );
    setIsWaiting(false);
    if (!result.error) {
      setAlreadyClaimed(true);
    }
    setApiState(result);
  };

  return (
    <Container variant="columns">
      <Container variant="left">
        <Card>
          <Box sx={{ p: [2, 3] }}>
            <InfoMessage title="Airdrop to LQTY community">
              <Paragraph>Weekly airdrop for LQTY holders and stakers.</Paragraph>

              <Paragraph sx={{ mt: 2 }}>
                For more details, check{" "}
                <Link href="https://docs.fluity.finance/airdrop-to-lqty-holders" target="_blank">
                  here. <Icon name="external-link-alt" size="xs" />
                </Link>
              </Paragraph>
            </InfoMessage>
          </Box>
        </Card>
        <Card>
          <Heading>Airdrop Claims</Heading>
          <Box sx={{ p: [2, 3] }}>
            <Flex sx={{ pl: "9px" }}>
              <Label htmlFor="week">Week</Label>
              <Select name="week" id="week" mb={3} value={selectedWeek} onChange={changeWeek}>
                {options}
              </Select>
            </Flex>

            <ActionDescription>
              Claim data for the week of {dateForWeek(selectedWeek)}.
            </ActionDescription>

            <StaticRow
              label={(claimable && alreadyClaimed && "Already Claimed") || "Claimable"}
              inputId="all-rewards"
              amount={(claimable && claimable.prettify()) || "N/A"}
              color={(claimable && alreadyClaimed && "grey") || "white"}
              unit="FLTY"
            />

            {(apiState.error && (
              <ErrorDescription>
                {apiState.error}
                {apiState.hash && (
                  <Link href={"https://bscscan.com/tx/" + apiState.hash} target="_blank">
                    {" "}
                    More details. <Icon name="external-link-alt" size="xs" />
                  </Link>
                )}
              </ErrorDescription>
            )) ||
              undefined}

            <Flex variant="layout.actions">
              {claimData ? (
                <Button
                  disabled={!(claimable && claimable.gt(0) && !alreadyClaimed)}
                  onClick={doClaim}
                >
                  Claim
                </Button>
              ) : (
                <Button variant="outline" onClick={checkClaim}>
                  Check Claim
                </Button>
              )}
            </Flex>
          </Box>
          {isWaiting && <LoadingOverlay />}
        </Card>
      </Container>

      <Container variant="right">
        <SystemStats />
      </Container>
    </Container>
  );
};
