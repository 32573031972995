import React, { useEffect, useState } from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoIcon } from "../../InfoIcon";
import { useLiquity } from "../../../hooks/LiquityContext";
import { Badge } from "../../Badge";
import { fetchPrices } from "../context/fetchPrices";
import { useFarmView } from "../context/FarmViewContext";

const selector = ({
  remainingStablecoinLiquidityMiningLQTYReward,
  totalStakedStablecoinUniTokens
}: LiquityStoreState) => ({
  remainingStablecoinLiquidityMiningLQTYReward,
  totalStakedStablecoinUniTokens
});

const deployedAt: { [key: string]: number } = {
  pancake: 1622170306,
  dodo: 1628604893
};

export const Yield: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  const { platform } = useFarmView();

  const {
    remainingStablecoinLiquidityMiningLQTYReward,
    totalStakedStablecoinUniTokens
  } = useLiquitySelector(selector);
  const [fltyPrice, setFltyPrice] = useState<Decimal | undefined>(undefined);
  const [uniLpPrice, setUniLpPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue =
    remainingStablecoinLiquidityMiningLQTYReward[platform].isZero ||
    totalStakedStablecoinUniTokens[platform].isZero;
  const uniTokenAddress = JSON.parse(addresses["stablecoinUniToken"])[platform];

  useEffect(() => {
    (async () => {
      try {
        const { fltyPriceUSD, uniLpPriceUSD } = await fetchPrices(uniTokenAddress);
        setFltyPrice(fltyPriceUSD);
        setUniLpPrice(uniLpPriceUSD);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [uniTokenAddress]);

  if (hasZeroValue || fltyPrice === undefined || uniLpPrice === undefined) {
    return null;
  }

  const remainingLqtyInUSD = remainingStablecoinLiquidityMiningLQTYReward[platform].mul(fltyPrice);
  const totalStakedUniLpInUSD = totalStakedStablecoinUniTokens[platform].mul(uniLpPrice);
  let aprPercentage = remainingLqtyInUSD.div(totalStakedUniLpInUSD).mul(100);

  const ts = Math.floor(Date.now() / 1000);
  const remainingSecs = deployedAt[platform] + 2592000 - ts;
  aprPercentage = aprPercentage.div(remainingSecs).mul(3600 * 24 * 365);
  const apyPercentage = aprPercentage.div(100).div(365).add(1).pow(365).sub(1).mul(100);

  if (aprPercentage.isZero) {
    return null;
  }

  return (
    <Badge>
      <Text>FLTY APR {aprPercentage.toString(2)}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" sx={{ minWidth: ["auto", "400px"] }}>
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the FLTY return on staked DODO
              LP tokens. Note the FLTY rewards only last for 1 month.
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace", mt: 2 }}>
              ($FLTY_REWARDS / $STAKED_DODO_LP) / remaining time * 1 year * 100 ={" "}
              <Text sx={{ fontWeight: "bold" }}> APR</Text>
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              ($
              {remainingLqtyInUSD.shorten()} / ${totalStakedUniLpInUSD.shorten()}) /{" "}
              {(remainingSecs / 3600 / 24).toFixed(2)} days * 365 days * 100 =
              <Text sx={{ fontWeight: "bold" }}> {aprPercentage.toString(2)}%</Text>
            </Paragraph>
            <br />
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              Daily compounded APY = (1 + APR / 365) ^ 365 - 1 = {apyPercentage.toString(2)}%
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
