import axios from "axios";

import { Decimal } from "@liquity/lib-base";

const Web3 = require("web3");

const abi = [
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
let web3: null | typeof Web3;

async function fetchCoingecko(tokenId: string) {
  try {
    const resp = await axios({
      method: "GET",
      url: "https://coingecko.p.rapidapi.com/simple/price",
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "coingecko.p.rapidapi.com",
        "x-rapidapi-key": "b8398f6512mshc89d315cf5b0c31p17a4bejsn378eb860e79b",
        useQueryString: true
      },
      params: {
        ids: tokenId,
        vs_currencies: "usd"
      }
    });
    return resp.data[tokenId].usd;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchLqtyPrice() {
  return {
    fltyPriceUSD: await fetchCoingecko("fluity")
  };
}

export async function fetchFltyPriceFromUni(fltyTokenAddress: string, uniTokenAddress: string) {
  let bnbPriceUSD = await fetchCoingecko("binancecoin");

  if (!web3) {
    web3 = new Web3(Web3.givenProvider);
  }
  const chainId = await web3.eth.net.getId();
  let wbnbAddress;
  if (chainId === 97) {
    // testnet
    wbnbAddress = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
  } else {
    wbnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  }

  const fltyToken = await new web3.eth.Contract(abi, fltyTokenAddress);
  let fltyBalance = await fltyToken.methods.balanceOf(uniTokenAddress).call();
  fltyBalance = Decimal.from(fltyBalance).div(1e18);

  const wbnbContract = await new web3.eth.Contract(abi, wbnbAddress);
  const wbnbBalance = await wbnbContract.methods.balanceOf(uniTokenAddress).call();
  const wbnbWorthUSD = Decimal.from(wbnbBalance).div(1e18).mul(bnbPriceUSD);
  const fltyPriceUSD = wbnbWorthUSD.div(fltyBalance);
  return { fltyPriceUSD };
}

export async function fetchFlusdPriceFromUni(flusdTokenAddress: string, uniTokenAddress: string) {
  let busdPriceUSD = await fetchCoingecko("binance-usd");

  if (!web3) {
    web3 = new Web3(Web3.givenProvider);
  }
  const busdAddress = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

  const flusdToken = await new web3.eth.Contract(abi, flusdTokenAddress);
  let flusdBalance = await flusdToken.methods.balanceOf(uniTokenAddress).call();
  flusdBalance = Decimal.from(flusdBalance).div(1e18);

  const busdContract = await new web3.eth.Contract(abi, busdAddress);
  const busdBalance = await busdContract.methods.balanceOf(uniTokenAddress).call();
  const busdWorthUSD = Decimal.from(busdBalance).div(1e18).mul(busdPriceUSD);
  const flusdPriceUSD = busdWorthUSD.div(flusdBalance);
  return { flusdPriceUSD };
}

export async function fetchFlusdPriceFromDodo(uniTokenAddress: string) {
  if (!web3) {
    web3 = new Web3(Web3.givenProvider);
  }
  const midPriceAbi = [
    {
      inputs: [],
      name: "getMidPrice",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256"
        }
      ],
      stateMutability: "view",
      type: "function"
    }
  ];

  const dodoContract = await new web3.eth.Contract(midPriceAbi, uniTokenAddress);
  // 1 BUSD = ? FLUSD
  const midPrice = await dodoContract.methods.getMidPrice().call();
  // 1 FLUSD = ? BUSD
  const flusdPriceUSD = Decimal.from(1e18).div(Decimal.from(midPrice));
  return { flusdPriceUSD };
}
