import React, { useEffect, useState } from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoIcon } from "../InfoIcon";
import { useLiquity } from "../../hooks/LiquityContext";
import { Badge } from "../Badge";
import { fetchFltyPriceFromUni } from "./context/fetchPrice";

const selector = ({ lusdInStabilityPool, remainingStabilityPoolLQTYReward }: LiquityStoreState) => ({
  lusdInStabilityPool,
  remainingStabilityPoolLQTYReward
});

export const Yield: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  let { lusdInStabilityPool, remainingStabilityPoolLQTYReward } = useLiquitySelector(selector);

  const [fltyPrice, setFltyPrice] = useState<Decimal | undefined>(undefined);
  const hasZeroValue = remainingStabilityPoolLQTYReward.isZero || lusdInStabilityPool.isZero;
  const fltyTokenAddress = addresses["lqtyToken"];
  const uniTokenAddress = addresses["uniToken"];

  useEffect(() => {
    (async () => {
      try {
        const { fltyPriceUSD } = await fetchFltyPriceFromUni(fltyTokenAddress, uniTokenAddress);
        setFltyPrice(fltyPriceUSD);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [fltyTokenAddress, uniTokenAddress]);

  if (hasZeroValue || fltyPrice === undefined) {
    return null;
  }

  const yearlyHalvingSchedule = 0.5; // 50% see LQTY distribution schedule for more info
  const remainingLqtyOneYear = remainingStabilityPoolLQTYReward.mul(yearlyHalvingSchedule);
  const remainingLqtyInUSD = remainingLqtyOneYear.mul(fltyPrice);
  const aprPercentage = remainingLqtyInUSD.div(lusdInStabilityPool).mul(100);
  const apyPercentage = aprPercentage.div(100).div(365).add(1).pow(365).sub(1).mul(100);

  if (aprPercentage.isZero) {
    return null;
  }

  return (
    <Badge>
      <Text>FLTY APR {aprPercentage.toString(2)}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" sx={{ width: ["220px", "518px"] }}>
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the FLTY return on the FLUSD
              deposited to the Stability Pool over the next year, not including your BNB gains from
              liquidations.
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace", mt: 2 }}>
              (($FLTY_REWARDS * YEARLY_DISTRIBUTION%) / DEPOSITED_FLUSD) * 100 ={" "}
              <Text sx={{ fontWeight: "bold" }}> APR</Text>
            </Paragraph>
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              ($
              {remainingLqtyInUSD.shorten()} * 50% / ${lusdInStabilityPool.shorten()}) * 100 =
              <Text sx={{ fontWeight: "bold" }}> {aprPercentage.toString(2)}%</Text>
            </Paragraph>
            <br />
            <Paragraph sx={{ fontSize: "12px", fontFamily: "monospace" }}>
              Daily compounded APY = (1 + APR / 365) ^ 365 - 1 = {apyPercentage.toString(2)}%
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
