import axios from "axios";

import { Decimal } from "@liquity/lib-base";

const Web3 = require("web3");

const abi = [
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
let web3: null | typeof Web3;

async function fetchCoingecko(tokenId: string) {
  try {
    const resp = await axios({
      method: "GET",
      url: "https://coingecko.p.rapidapi.com/simple/price",
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "coingecko.p.rapidapi.com",
        "x-rapidapi-key": "b8398f6512mshc89d315cf5b0c31p17a4bejsn378eb860e79b",
        useQueryString: true
      },
      params: {
        ids: tokenId,
        vs_currencies: "usd"
      }
    });
    return resp.data[tokenId].usd;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchPrices(
  fltyTokenAddress: string,
  uniTokenAddress: string,
  useUniForFltyPrice: boolean = false
) {
  let bnbPriceUSD = await fetchCoingecko("binancecoin");

  if (!web3) {
    web3 = new Web3(Web3.givenProvider);
  }
  const chainId = await web3.eth.net.getId();
  let wbnbAddress;
  if (chainId === 97) {
    // testnet
    wbnbAddress = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";
  } else {
    wbnbAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  }

  const uniToken = await new web3.eth.Contract(abi, uniTokenAddress);
  let uniTotalSupply = await uniToken.methods.totalSupply().call();
  uniTotalSupply = Decimal.from(uniTotalSupply).div(1e18);

  const wbnbContract = await new web3.eth.Contract(abi, wbnbAddress);
  const wbnbBalance = await wbnbContract.methods.balanceOf(uniTokenAddress).call();
  const reservedUSD = Decimal.from(wbnbBalance).div(1e18).mul(2).mul(bnbPriceUSD);
  const uniLpPriceUSD = reservedUSD.div(Decimal.from(uniTotalSupply));

  let fltyPriceUSD;
  if (!useUniForFltyPrice) {
    fltyPriceUSD = await fetchCoingecko("fluity");
  } else {
    const fltyToken = await new web3.eth.Contract(abi, fltyTokenAddress);
    let fltyBalance = await fltyToken.methods.balanceOf(uniTokenAddress).call();
    fltyBalance = Decimal.from(fltyBalance).div(1e18);
    const wbnbWorthUSD = Decimal.from(wbnbBalance).div(1e18).mul(bnbPriceUSD);
    fltyPriceUSD = wbnbWorthUSD.div(fltyBalance);
  }
  return { fltyPriceUSD, uniLpPriceUSD };
}
