import { Box, Card, Container, Link, Paragraph } from "theme-ui";

import { Trove } from "../components/Trove/Trove";
import { Stability } from "../components/Stability/Stability";
import { SystemStats } from "../components/SystemStats";
import { Prices } from "../components/Prices";
import { Staking } from "../components/Staking/Staking";
import { Vesting } from "../components/Vesting/Vesting";
import React from "react";

export const Dashboard: React.FC = () => (
  <Container variant="columns">
    <Container variant="left">
      <Trove />
      <Stability />
      <Staking />
      <Vesting />
    </Container>

    <Container variant="right">
      <Prices />
      <Card>
        <Box sx={{ p: [2, 3] }}>
          <Paragraph>
            <strong>Fluity</strong> is an interest-free borrowing protocol on Binance Smart Chain.
            Open a Trove to mint $FLUSD debt against your $BNB.
            Deposit your $FLUSD into the Stability Pool to earn rewards in $FLTY.
            Stake the $FLTY you earn to receive protocol income in $FLUSD and $BNB.
            $FLTY rewards can be withdrawn from the Vesting Pool.
            Read the <Link href="https://docs.fluity.finance/">protocol documentation</Link> for details!
            Join our community in <Link href="https://discord.gg/etvyP53Sdc">Discord</Link> and {" "}
            <Link href="https://t.me/fluity">Telegram</Link> today!
          </Paragraph>
        </Box>
      </Card>
      <SystemStats />
    </Container>
  </Container>
);
