import React from "react";
import { Button } from "theme-ui";

import { Decimal } from "@liquity/lib-base";

import { useLiquity } from "../../../hooks/LiquityContext";
import { useTransactionFunction } from "../../Transaction";

type WithdrawProps = {
  amount: Decimal;
  isDanger?: boolean;
  disabled?: boolean;
};

export const Withdraw: React.FC<WithdrawProps> = ({ amount, isDanger, disabled, children}) => {
  const { liquity } = useLiquity();

  const [sendTransaction] = useTransactionFunction(
    "vesting",
    liquity.send.withdrawEarning.bind(liquity.send, amount)
  );

  return (
    <Button onClick={sendTransaction} disabled={disabled} variant={isDanger ? "danger" : "primary"}>
      {children}
    </Button>
  );
};
