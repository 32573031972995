import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Link, Paragraph } from "theme-ui";
import { useLiquity } from "../../../../hooks/LiquityContext";
import { Icon } from "../../../Icon";
import { InfoMessage } from "../../../InfoMessage";
import { useFarmView } from "../../context/FarmViewContext";
import { RemainingLQTY } from "../RemainingLQTY";
import { Yield } from "../Yield";

const uniLink = (lqtyAddress: string) =>
  `https://v1exchange.pancakeswap.finance/#/add/BNB/${lqtyAddress}`;
const uniForkLink = (lqtyAddress: string) => `/pancakeswap/index.html#/add/BNB/${lqtyAddress}`;

export const Inactive: React.FC = () => {
  const { dispatchEvent } = useFarmView();

  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  const handleStakePressed = useCallback(() => {
    dispatchEvent("STAKE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        PancakeSwap Liquidity Farm
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLQTY />
        </Flex>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You aren't farming FLTY.">
          <Paragraph>You can farm FLTY by staking your PancakeSwap BNB/FLTY LP tokens.</Paragraph>
          <Paragraph sx={{ mt: 2 }}>
            Since the V1 portal is currently unavailable on{" "}
            <del>
              <Link href={uniLink(addresses["lqtyToken"])} target="_blank">
                PancakeSwap UI <Icon name="external-link-alt" size="xs" />
              </Link>
            </del>
            , we've forked its front-end{" "}
            <Link href="https://github.com/fluity-finance/pancake-swap-interface-v1" target="_blank">
              GitHub repo <Icon name="external-link-alt" size="xs" />
            </Link>{" "}
            with V1 enabled, so you can add / remove liquidity from{" "}
            <Link href={uniForkLink(addresses["lqtyToken"])} target="_blank">
              here <Icon name="external-link-alt" size="xs" />
            </Link>{" "}
            .
          </Paragraph>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
            <Yield />
          </Flex>
          <Button onClick={handleStakePressed}>Stake</Button>
        </Flex>
      </Box>
    </Card>
  );
};
