import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Heading, Container, Link } from "theme-ui";

import { useLiquity } from "../hooks/LiquityContext";
import { fetchFltyPriceFromUni, fetchFlusdPriceFromDodo } from "./Stability/context/fetchPrice";
import { Icon } from "./Icon";

async function fetchCoingecko(tokenId: string) {
  try {
    const resp = await axios({
      method: "GET",
      url: "https://coingecko.p.rapidapi.com/simple/price",
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "coingecko.p.rapidapi.com",
        "x-rapidapi-key": "b8398f6512mshc89d315cf5b0c31p17a4bejsn378eb860e79b",
        useQueryString: true
      },
      params: {
        ids: tokenId,
        vs_currencies: "usd"
      }
    });
    return resp.data[tokenId].usd;
  } catch (e) {
    console.log(e);
  }
}

export const Prices: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  const fltyTokenAddress = addresses["lqtyToken"];
  const uniTokenAddress = addresses["uniToken"];
  const stablecoinUniTokenAddress = JSON.parse(addresses["stablecoinUniToken"])["dodo"];

  const [bnbPrice, setBNBPrice] = useState("0");
  const [fltyPrice, setFLTYPrice] = useState("0");
  const [flusdPrice, setFLUSDPrice] = useState("0");
  useEffect(() => {
    const fetchBNB = async () => {
      const bnb = await fetchCoingecko("binancecoin");
      setBNBPrice(bnb);
    };
    const fetchFLTY = async () => {
      const { fltyPriceUSD } = await fetchFltyPriceFromUni(fltyTokenAddress, uniTokenAddress);
      setFLTYPrice(fltyPriceUSD.shorten());
    };
    const fetchFLUSD = async () => {
      const { flusdPriceUSD } = await fetchFlusdPriceFromDodo(stablecoinUniTokenAddress);
      setFLUSDPrice(flusdPriceUSD.shorten());
    };
    fetchBNB();
    fetchFLTY();
    fetchFLUSD();
  });

  return (
    <Card>
      <Heading>Prices</Heading>
      <Container
        variant="columns"
        style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}
      >
        <Container variant="left3rd">
          <img src={"icons/bnb.svg"} alt={"BNB"} />
          <div>BNB</div>
          <div>${bnbPrice}</div>
          <Link href={"https://www.coingecko.com/en/coins/binance-coin"}>
            <Icon name="info-circle" />
          </Link>
          {"   "}
          <Link href={"https://data.chain.link/bsc/mainnet/crypto-usd/bnb-usd"}>
            <Icon name="satellite-dish" />
          </Link>
        </Container>
        <Container variant="mid3rd">
          <img src={"icons/flty-icon.png"} alt={"FLTY"} />
          <div>FLTY</div>
          <div>${fltyPrice}</div>
          <Link href={"https://coinmarketcap.com/currencies/fluity/"}>
            <Icon name="info-circle" />
          </Link>
          {"   "}
          <Link
            href={
              "https://v1exchange.pancakeswap.finance/#/swap?outputCurrency=0x83b325dbA77d55644619a4Ff29D42EE4487BCf31"
            }
          >
            <Icon name="exchange-alt" />
          </Link>
        </Container>
        <Container variant="right3rd">
          <img src={"icons/flusd-icon.png"} alt={"FLUSD"} />
          <div>FLUSD</div>
          <div>${flusdPrice}</div>
          <Link href={"https://coinmarketcap.com/currencies/fluity-usd/"}>
            <Icon name="info-circle" />
          </Link>
          {"   "}
          <Link
            href={
              "https://app.dodoex.io/exchange/BUSD-FLUSD?network=bsc-mainnet"
            }
          >
            <Icon name="exchange-alt" />
          </Link>
        </Container>
      </Container>
    </Card>
  );
};
