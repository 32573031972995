import React from "react";
import { Flex } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useFarmView } from "../context/FarmViewContext";

const selector = ({ remainingStablecoinLiquidityMiningLQTYReward }: LiquityStoreState) => ({
  remainingStablecoinLiquidityMiningLQTYReward
});

export const RemainingLQTY: React.FC = () => {
  const { remainingStablecoinLiquidityMiningLQTYReward } = useLiquitySelector(selector);
  const { platform } = useFarmView();

  return (
    <Flex sx={{ mr: 2, fontSize: 2, fontWeight: "medium" }}>
      {remainingStablecoinLiquidityMiningLQTYReward[platform].prettify(0)} FLTY remaining
    </Flex>
  );
};
