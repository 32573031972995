import React, { useState, useCallback, useEffect, useRef } from "react";
import { LiquityStoreState, Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { FarmViewContext } from "./FarmViewContext";
import { transitions } from "./transitions";
import type { FarmView, FarmEvent } from "./transitions";

type FarmStablecoinProps = {
  platform: string;
};

const transition = (view: FarmView, event: FarmEvent): FarmView => {
  const nextView = transitions[view][event] ?? view;
  return nextView;
};

const getInitialView = (
  stablecoinLiquidityMiningStake: Decimal,
  remainingStablecoinLiquidityMiningLQTYReward: Decimal
): FarmView => {
  if (remainingStablecoinLiquidityMiningLQTYReward.isZero) return "DISABLED";
  if (stablecoinLiquidityMiningStake.isZero) return "INACTIVE";
  return "ACTIVE";
};

const selector = ({
  stablecoinLiquidityMiningStake,
  remainingStablecoinLiquidityMiningLQTYReward
}: LiquityStoreState) => ({ stablecoinLiquidityMiningStake, remainingStablecoinLiquidityMiningLQTYReward });

export const FarmViewProvider: React.FC<FarmStablecoinProps> = props => {
  const { children, platform } = props;
  const { stablecoinLiquidityMiningStake, remainingStablecoinLiquidityMiningLQTYReward } = useLiquitySelector(selector);

  const [view, setView] = useState<FarmView>(
    getInitialView(stablecoinLiquidityMiningStake[platform], remainingStablecoinLiquidityMiningLQTYReward[platform])
  );
  const viewRef = useRef<FarmView>(view);

  const dispatchEvent = useCallback((event: FarmEvent) => {
    const nextView = transition(viewRef.current, event);

    console.log(
      "dispatchEvent() [current-view, event, next-view]",
      viewRef.current,
      event,
      nextView
    );
    setView(nextView);
  }, []);

  useEffect(() => {
    viewRef.current = view;
  }, [view]);

  useEffect(() => {
    if (stablecoinLiquidityMiningStake.isZero) {
      dispatchEvent("UNSTAKE_AND_CLAIM_CONFIRMED");
    }
  }, [stablecoinLiquidityMiningStake.isZero, dispatchEvent]);

  const provider = {
    view,
    dispatchEvent,
    platform
  };

  return <FarmViewContext.Provider value={provider}>{children}</FarmViewContext.Provider>;
};
