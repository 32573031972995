import React from "react";
import { Card, Heading, Box, Flex } from "theme-ui";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { InfoMessage } from "../../../InfoMessage";
import { UnstakeAndClaim } from "../UnstakeAndClaim";
import { RemainingLQTY } from "../RemainingLQTY";
import { StaticRow } from "../../../Trove/Editor";
import { GT, DLP } from "../../../../strings";
import { useFarmView } from "../../context/FarmViewContext";

const selector = ({ stablecoinLiquidityMiningStake, stablecoinLiquidityMiningLQTYReward }: LiquityStoreState) => ({
  stablecoinLiquidityMiningStake,
  stablecoinLiquidityMiningLQTYReward
});

export const Disabled: React.FC = () => {
  const { stablecoinLiquidityMiningStake, stablecoinLiquidityMiningLQTYReward } = useLiquitySelector(selector);
  const { platform } = useFarmView();
  const hasStake = !stablecoinLiquidityMiningStake[platform].isZero;

  return (
    <Card>
      <Heading>
        FLUSD Liquidity Farm - {`${platform.toLocaleUpperCase()}`}
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLQTY />
        </Flex>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="Liquidity farming period has finished">
          <Flex>There are no more FLTY rewards left to farm.</Flex>
        </InfoMessage>
        {hasStake && (
          <>
            <Box sx={{ border: 1, pt: 3, borderRadius: 3 }}>
              <StaticRow
                label="Stake"
                inputId="farm-deposit"
                amount={stablecoinLiquidityMiningStake[platform].prettify(4)}
                unit={DLP}
              />
              <StaticRow
                label="Reward"
                inputId="farm-reward"
                amount={stablecoinLiquidityMiningLQTYReward[platform].prettify(4)}
                color={stablecoinLiquidityMiningLQTYReward[platform].nonZero && "success"}
                unit={GT}
              />
            </Box>
            <UnstakeAndClaim />
          </>
        )}
      </Box>
    </Card>
  );
};
