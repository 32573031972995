import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Paragraph, Link } from "theme-ui";
import { LP, GT } from "../../../../strings";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { Icon } from "../../../Icon";
import { LoadingOverlay } from "../../../LoadingOverlay";
import { useMyTransactionState } from "../../../Transaction";
import { DisabledEditableRow, StaticRow } from "../../../Trove/Editor";
import { useFarmView } from "../../context/FarmViewContext";
import { RemainingLQTY } from "../RemainingLQTY";
import { ClaimReward } from "./ClaimReward";
import { UnstakeAndClaim } from "../UnstakeAndClaim";
import { Yield } from "../Yield";
import { useLiquity } from "../../../../hooks/LiquityContext";
import { InfoMessage } from "../../../InfoMessage";

const selector = ({
  liquidityMiningStake,
  liquidityMiningLQTYReward,
  totalStakedUniTokens
}: LiquityStoreState) => ({
  liquidityMiningStake,
  liquidityMiningLQTYReward,
  totalStakedUniTokens
});
const transactionId = /farm-/i;
const uniForkLink = (lqtyAddress: string) => `/pancakeswap/index.html#/add/BNB/${lqtyAddress}`;

export const Active: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  const { dispatchEvent } = useFarmView();
  const {
    liquidityMiningStake,
    liquidityMiningLQTYReward,
    totalStakedUniTokens
  } = useLiquitySelector(selector);

  const handleAdjustPressed = useCallback(() => {
    dispatchEvent("ADJUST_PRESSED");
  }, [dispatchEvent]);

  const transactionState = useMyTransactionState(transactionId);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const poolShare = liquidityMiningStake.mulDiv(100, totalStakedUniTokens);

  return (
    <Card>
      <Heading>
        PancakeSwap Liquidity Farm
        {!isTransactionPending && (
          <Flex sx={{ justifyContent: "flex-end" }}>
            <RemainingLQTY />
          </Flex>
        )}
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="PancakeSwap Version">
          <Paragraph>
            Since the V1 portal is currently unavailable on{" "}
            <del>
              <Link href={uniForkLink(addresses["lqtyToken"])} target="_blank">
                PancakeSwap UI <Icon name="external-link-alt" size="xs" />
              </Link>
            </del>
            , we've forked its front-end{" "}
            <Link href="https://github.com/fluity-finance/pancake-swap-interface-v1" target="_blank">
              GitHub repo <Icon name="external-link-alt" size="xs" />
            </Link>{" "}
            with V1 enabled, so you can add / remove liquidity from{" "}
            <Link href={uniForkLink(addresses["lqtyToken"])} target="_blank">
              here <Icon name="external-link-alt" size="xs" />
            </Link>{" "}
            .
          </Paragraph>
        </InfoMessage>

        <Box>
          <DisabledEditableRow
            label="Stake"
            inputId="farm-stake"
            amount={liquidityMiningStake.prettify(4)}
            unit={LP}
          />
          {poolShare.infinite ? (
            <StaticRow label="Pool share" inputId="farm-share" amount="N/A" />
          ) : (
            <StaticRow
              label="Pool share"
              inputId="farm-share"
              amount={poolShare.prettify(4)}
              unit={"%"}
            />
          )}
          <Flex sx={{ alignItems: "center" }}>
            <StaticRow
              label="Reward"
              inputId="farm-reward"
              amount={liquidityMiningLQTYReward.prettify(4)}
              color={liquidityMiningLQTYReward.nonZero && "success"}
              unit={GT}
            />
            <Flex sx={{ justifyContent: "flex-end", flex: 1 }}>
              <Yield />
            </Flex>
          </Flex>
        </Box>

        <Flex variant="layout.actions">
          <Button variant="outline" onClick={handleAdjustPressed}>
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>
          <ClaimReward />
        </Flex>
        <Flex>
          <UnstakeAndClaim />
        </Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
