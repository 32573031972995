import { AddressZero } from "@ethersproject/constants";

export type LiquityFrontendConfig = {
  frontendTag: string;
  infuraApiKey?: string;
  testnetOnly?: boolean;
};

const defaultConfig: LiquityFrontendConfig = {
  frontendTag: AddressZero
};

let configPromise: Promise<LiquityFrontendConfig> | undefined = undefined;

const fetchConfig = async () => {
  return { ...defaultConfig };
};

export const getConfig = (): Promise<LiquityFrontendConfig> => {
  if (!configPromise) {
    configPromise = fetchConfig();
  }

  return configPromise;
};
