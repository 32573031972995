import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Link, Paragraph } from "theme-ui";
import { useLiquity } from "../../../../hooks/LiquityContext";
import { Icon } from "../../../Icon";
import { useFarmView } from "../../context/FarmViewContext";
import { RemainingLQTY } from "../RemainingLQTY";
import { Yield } from "../Yield";

const uniLink = (address: string) =>
  `https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${address}`;
const dodoLink = `https://app.dodoex.io/pool/list/0xc09ba575cd41c9948596fe3089874f0217d62237?network=bsc-mainnet`;

export const Inactive: React.FC = () => {
  const { dispatchEvent, platform } = useFarmView();

  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();

  const handleStakePressed = useCallback(() => {
    dispatchEvent("STAKE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>
        FLUSD Liquidity Farm - {`${platform.toLocaleUpperCase()}`}
        <Flex sx={{ justifyContent: "flex-end" }}>
          <RemainingLQTY />
        </Flex>
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <Paragraph>
          This farm is to incentivize FLUSD liquidity on{" "}
          {platform === "pancake" ? "PancakeSwap FLUSD/BUSD pool" : "DODO pegged FLUSD/BUSD pool"}.
        </Paragraph>
        {(platform === "pancake" && (
          <Paragraph sx={{ mt: 2 }}>
            You can add liquidity in PancakeSwap V2 for{" "}
            <Link href={uniLink(addresses["lusdToken"])} target="_blank">
              FLUSD/BUSD pair <Icon name="external-link-alt" size="xs" />
            </Link>
            .
          </Paragraph>
        )) || (
          <Paragraph sx={{ mt: 2 }}>
            You can add liquidity in DODO for{" "}
            <Link href={dodoLink} target="_blank">
              FLUSD/BUSD pair <Icon name="external-link-alt" size="xs" />
            </Link>
            .
          </Paragraph>
        )}

        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", flex: 1 }}>
            <Yield />
          </Flex>
          <Button onClick={handleStakePressed}>Stake</Button>
        </Flex>
      </Box>
    </Card>
  );
};
