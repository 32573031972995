import React, { useEffect, useState } from "react";
import { Card, Heading, Box, Flex, Text } from "theme-ui";

import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { LoadingOverlay } from "../LoadingOverlay";
import { StaticRow } from "../Trove/Editor";
import { useMyTransactionState } from "../Transaction";
import { Withdraw } from "./actions/Withdraw";
import { useLiquity } from "../../hooks/LiquityContext";
import { Abbreviation } from "../Abbreviation";
import { Icon } from "../Icon";

const Web3 = require("web3");
let web3: null | typeof Web3;
const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address"
      }
    ],
    name: "earnedBalances",
    outputs: [
      {
        internalType: "uint256",
        name: "total",
        type: "uint256"
      },
      {
        internalType: "uint256[2][]",
        name: "earningsData",
        type: "uint256[2][]"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

const zeroish = (d: Decimal) => d.lte(Decimal.from(0.000001));

const selector = ({ earnings }: LiquityStoreState) => ({
  earnings
});

const fetchEarnedRewards = async (contractAddress: string, userAddress: string) => {
  if (!web3) {
    web3 = new Web3(Web3.givenProvider);
  }
  const stakingContract = await new web3.eth.Contract(abi, contractAddress);
  const resp = await stakingContract.methods.earnedBalances(userAddress).call();
  return resp.earningsData;
};

export const Vesting: React.FC = () => {
  const {
    account,
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  const lqtyStakingAddress = addresses["lqtyStaking"];
  const transactionState = useMyTransactionState("vesting");
  const isWaitingForTransaction =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const { earnings } = useLiquitySelector(selector);
  let [withdrawableNoPenalty, withdrawableTotal, penalty] = earnings;
  if (!withdrawableTotal.isZero) {
    // Bypass vesting contract's decimal precision
    const oneWei = Decimal.fromBigNumberString("1");
    withdrawableTotal = withdrawableTotal.sub(oneWei);
  }

  const [earningArray, setEarningArray] = useState<Array<Array<string>>>([]);
  const [showPlan, setShowPlan] = useState(false);
  useEffect(() => {
    fetchEarnedRewards(lqtyStakingAddress, account).then(earningArray => {
      setEarningArray(earningArray);
    });
  }, [account, lqtyStakingAddress]);

  return (
    <Card>
      <Heading>Vesting</Heading>
      <Box sx={{ p: [2, 3] }}>
        <StaticRow
          label="All Rewards"
          inputId="all-rewards"
          amount={withdrawableTotal.add(penalty).prettify()}
          unit="FLTY"
        />

        <StaticRow
          label="Withdrawable without penalty"
          inputId="withdrawable-no-penalty"
          amount={withdrawableNoPenalty.prettify()}
          unit="FLTY"
        />

        <StaticRow
          label="Withdrawable with penalty"
          inputId="withdrawable-with-penalty"
          amount={withdrawableTotal.prettify()}
          color="red"
          unit="FLTY"
        />

        <div
          style={{ paddingLeft: "15px", paddingBottom: "16px", fontSize: "14px" }}
          onClick={() => {
            setShowPlan(!showPlan);
          }}
        >
          <div>
            <Icon name={showPlan ? "minus-circle" : "plus-circle"} />
            &nbsp;&nbsp;{showPlan ? "Hide" : "Show"} vesting plan
          </div>
        </div>

        <Box
          sx={{
            p: [2, 3],
            border: "1px solid grey",
            mb: 30,
            display: showPlan ? "block" : "none"
          }}
        >
          <Box
            as="table"
            sx={{
              pl: [1, 1],
              width: "100%",
              textAlign: "center",
              lineHeight: 1.15
            }}
          >
            <colgroup>
              <col style={{ width: "200px" }} />
              <col />
              <col style={{ width: "200px" }} />
            </colgroup>

            <thead>
              <tr>
                <th>
                  <Abbreviation short="Date">Fully Vesting Date</Abbreviation>
                </th>
                <th />
                <th>
                  <Abbreviation short="Amount">Vesting Amount</Abbreviation>
                  <Box sx={{ fontSize: [0, 1], fontWeight: "body", opacity: 0.5 }}>FLTY</Box>
                </th>
              </tr>
            </thead>
            <tbody>
              {earningArray.map((e, i) => (
                <tr key={`earning-${i}`}>
                  <td
                    style={{
                      alignItems: "center",
                      height: "40px"
                    }}
                  >
                    <Text
                      variant="address"
                      sx={{
                        width: ["73px", "unset"],
                        overflow: "hidden",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative"
                      }}
                    >
                      <Abbreviation short={new Date(Number(e[1]) * 1000).toLocaleDateString()}>
                        {new Date(Number(e[1]) * 1000).toLocaleString()}
                      </Abbreviation>
                    </Text>
                  </td>
                  <td />
                  <td>{Decimal.fromBigNumberString(e[0]).prettify()}</td>
                </tr>
              ))}
            </tbody>
          </Box>
        </Box>

        <Flex variant="layout.actions">
          <Withdraw amount={withdrawableNoPenalty} disabled={zeroish(withdrawableNoPenalty)}>
            No-penalty withdraw
          </Withdraw>
          <Withdraw amount={withdrawableTotal} isDanger={true} disabled={zeroish(withdrawableTotal)}>
            Withdraw All
          </Withdraw>
        </Flex>
      </Box>
      {isWaitingForTransaction && <LoadingOverlay />}
    </Card>
  );
};
