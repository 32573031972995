import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

const selector = ({
  stablecoinUniTokenBalance,
  stablecoinUniTokenAllowance,
  stablecoinLiquidityMiningStake
}: LiquityStoreState) => ({
  stablecoinUniTokenBalance,
  stablecoinUniTokenAllowance,
  stablecoinLiquidityMiningStake
});

type FarmStakeValidation = {
  isValid: boolean;
  hasApproved: boolean;
  hasEnoughUniToken: boolean;
  isWithdrawing: boolean;
  amountChanged: Decimal;
  maximumStake: Decimal;
  hasSetMaximumStake: boolean;
};

export const useValidationState = (amount: Decimal, platform: string): FarmStakeValidation => {
  const { stablecoinUniTokenBalance, stablecoinUniTokenAllowance, stablecoinLiquidityMiningStake } = useLiquitySelector(selector);
  const isWithdrawing = stablecoinLiquidityMiningStake[platform].gt(amount);
  const amountChanged = isWithdrawing
    ? stablecoinLiquidityMiningStake[platform].sub(amount)
    : Decimal.from(amount).sub(stablecoinLiquidityMiningStake[platform]);
  const maximumStake = stablecoinLiquidityMiningStake[platform].add(stablecoinUniTokenBalance[platform]);
  const hasSetMaximumStake = amount.eq(maximumStake);

  if (isWithdrawing) {
    return {
      isValid: true,
      hasApproved: true,
      hasEnoughUniToken: true,
      isWithdrawing,
      amountChanged,
      maximumStake,
      hasSetMaximumStake
    };
  }

  const hasApproved = !stablecoinUniTokenAllowance.isZero && stablecoinUniTokenAllowance[platform].gte(amountChanged);
  const hasEnoughUniToken = !stablecoinUniTokenBalance.isZero && stablecoinUniTokenBalance[platform].gte(amountChanged);

  return {
    isValid: hasApproved && hasEnoughUniToken,
    hasApproved,
    hasEnoughUniToken,
    isWithdrawing,
    amountChanged,
    maximumStake,
    hasSetMaximumStake
  };
};
