import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Paragraph, Link } from "theme-ui";
import { DLP, GT } from "../../../../strings";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { Icon } from "../../../Icon";
import { LoadingOverlay } from "../../../LoadingOverlay";
import { useMyTransactionState } from "../../../Transaction";
import { DisabledEditableRow, StaticRow } from "../../../Trove/Editor";
import { useFarmView } from "../../context/FarmViewContext";
import { RemainingLQTY } from "../RemainingLQTY";
import { ClaimReward } from "./ClaimReward";
import { UnstakeAndClaim } from "../UnstakeAndClaim";
import { Yield } from "../Yield";
import { useLiquity } from "../../../../hooks/LiquityContext";
import { InfoMessage } from "../../../InfoMessage";

const selector = ({
  stablecoinLiquidityMiningStake,
  stablecoinLiquidityMiningLQTYReward,
  totalStakedStablecoinUniTokens
}: LiquityStoreState) => ({
  stablecoinLiquidityMiningStake,
  stablecoinLiquidityMiningLQTYReward,
  totalStakedStablecoinUniTokens
});
const transactionId = /farmstablecoin-/i;
const uniLink = (address: string) =>
  `https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/${address}`;
const dodoLink = `https://app.dodoex.io/pool/list/0xc09ba575cd41c9948596fe3089874f0217d62237?network=bsc-mainnet`;

export const Active: React.FC = () => {
  const {
    liquity: {
      connection: { addresses }
    }
  } = useLiquity();
  const { dispatchEvent, platform } = useFarmView();
  const {
    stablecoinLiquidityMiningStake,
    stablecoinLiquidityMiningLQTYReward,
    totalStakedStablecoinUniTokens
  } = useLiquitySelector(selector);

  const handleAdjustPressed = useCallback(() => {
    dispatchEvent("ADJUST_PRESSED");
  }, [dispatchEvent]);

  const transactionState = useMyTransactionState(transactionId);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const poolShare = stablecoinLiquidityMiningStake[platform].mulDiv(
    100,
    totalStakedStablecoinUniTokens[platform]
  );

  return (
    <Card>
      <Heading>
        FLUSD Liquidity Farm - {`${platform.toLocaleUpperCase()}`}
        {!isTransactionPending && (
          <Flex sx={{ justifyContent: "flex-end" }}>
            <RemainingLQTY />
          </Flex>
        )}
      </Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="PancakeSwap Version">
          <Paragraph sx={{ mt: 2 }}>
            Note the pool we incentivize is{" "}
            <Link
              href={(platform === "pancake" && uniLink(addresses["lusdToken"])) || dodoLink}
              target="_blank"
            >
              {(platform === "pancake" && "Pancake pool") || "DODO pegged pool"} of FLUSD/BUSD{" "}
              <Icon name="external-link-alt" size="xs" />
            </Link>{" "}
            .
          </Paragraph>
        </InfoMessage>

        <Box>
          <DisabledEditableRow
            label="Stake"
            inputId="farm-stake"
            amount={stablecoinLiquidityMiningStake[platform].prettify(4)}
            unit={DLP}
          />
          {poolShare.infinite ? (
            <StaticRow label="Pool share" inputId="farm-share" amount="N/A" />
          ) : (
            <StaticRow
              label="Pool share"
              inputId="farm-share"
              amount={poolShare.prettify(4)}
              unit={"%"}
            />
          )}
          <Flex sx={{ alignItems: "center" }}>
            <StaticRow
              label="Reward"
              inputId="farm-reward"
              amount={stablecoinLiquidityMiningLQTYReward[platform].prettify(4)}
              color={stablecoinLiquidityMiningLQTYReward[platform].nonZero && "success"}
              unit={GT}
            />
            <Flex sx={{ justifyContent: "flex-end", flex: 1 }}>
              <Yield />
            </Flex>
          </Flex>
        </Box>

        <Flex variant="layout.actions">
          <Button variant="outline" onClick={handleAdjustPressed}>
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>
          <ClaimReward />
        </Flex>
        <Flex>
          <UnstakeAndClaim />
        </Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
