import { Decimal } from "@liquity/lib-base";

const Web3 = require("web3");
const abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "merkleIndex",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      },
      {
        internalType: "bytes32[]",
        name: "merkleProof",
        type: "bytes32[]"
      }
    ],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "merkleIndex",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256"
      }
    ],
    name: "isClaimed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

const mkAddress = "0xe699acE439379C98740E03fD93a1935D1D4ee862";
const web3 = new Web3(Web3.givenProvider);

export const isClaimed = async (mkIndex: number, index: number): Promise<boolean> => {
  const mkContract = new web3.eth.Contract(abi, mkAddress);
  return await mkContract.methods.isClaimed(mkIndex, index).call();
};

export const claim = async (
  mkIndex: number,
  index: number,
  account: string,
  amount: Decimal,
  proof: Array<string>
): Promise<{ error?: string; hash?: string }> => {
  const mkContract = new web3.eth.Contract(abi, mkAddress);
  try {
    const resp = await mkContract.methods
      .claim(mkIndex, index, amount.toString(), proof)
      .send({ from: account });
    return {
      hash: resp.transactionHash
    };
  } catch (error) {
    console.error(error);
    switch (error.code) {
      case 4001:
        return {
          error: error.message
        };
      case -32603:
        return {
          error: "Transaction underpriced."
        };
      default:
        return {
          error: "Transaction has been reverted.",
          hash: error.receipt?.transactionHash
        };
    }
  }
};
