import { Container } from "theme-ui";
import { SystemStats } from "../components/SystemStats";
import { Farm as FarmPanel } from "../components/Farm/Farm";
import { FarmStablecoin as FarmStablecoinPanel } from "../components/FarmStablecoin/FarmStablecoin";
import { Vesting } from "../components/Vesting/Vesting";
import { FarmViewProvider as FarmStablecoinViewProvider } from "../components/FarmStablecoin/context/FarmViewProvider";

export const Farm: React.FC = () => (
  <Container variant="columns" sx={{ justifyContent: "flex-start" }}>
    <Container variant="left">
      <FarmPanel />
      <FarmStablecoinViewProvider platform="dodo">
        <FarmStablecoinPanel />
      </FarmStablecoinViewProvider>
      <FarmStablecoinViewProvider platform="pancake">
        <FarmStablecoinPanel />
      </FarmStablecoinViewProvider>
      <Vesting />
    </Container>

    <Container variant="right">
      <SystemStats />
    </Container>
  </Container>
);
